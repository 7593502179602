import { createRouter, createWebHistory } from 'vue-router'
import { routes } from "./router";
import { test, formal } from '@/config/index';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

if ( test) {} else {
    /*router.beforeEach((to) => {
        const user = JSON.parse(localStorage.Header || "{}")
        if (to.name !== 'login' && !user.Guid) {
            router.replace({ name: 'login' })
            return false
        } else {
            return true
        }
    })*/
}

export default router