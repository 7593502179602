import { defineStore } from 'pinia'

export const storeInit = defineStore({
    // id 是必填的，并且所有 Store 中唯一。因为Pinia会将它在devtools显示
    id: 'store',
    state() {
        return {
            user: JSON.parse(localStorage.user || `{
                "school":"school"
            }`),
            Header: JSON.parse(localStorage.Header || "{}"),
            out_url: "",
            LoginData: JSON.parse(localStorage.LoginData || "{}"),
        }
    },
    getters: {},
    actions: {}
})