<!-- @format -->

<template>
    <el-config-provider :locale="zhCn">
        <router-view v-if="isRouterAlive" />
    </el-config-provider>
</template>
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { storeInit } from "@/store/index.js";
import { test, formal } from "@/config/index";

export default {
    name: "app",
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            zhCn,
            form: {},
            isRouterAlive: true,
            store: storeInit(),
        };
    },
    mounted() {
        // var in_data = {
        //     ...this._ajax.get_url_argument_obj(),
        //     ...this.$route.params,
        //     ...this.$route.query,
        // };
        // if (in_data["[Header]Guid"]) {
        //     this._ajax.store.Header = {
        //         // BodyID: in_data["[Header]BodyID"],
        //         // CreateTime: in_data["[Header]CreateTime"],
        //         // CreateUserInfoID: in_data["[Header]CreateUserInfoID"],
        //         // Email: in_data["[Header]Email"],
        //         // ExpirationTime: in_data["[Header]ExpirationTime"],
        //         // Grade: in_data["[Header]Grade"],
        //         Guid: in_data["[Header]Guid"],
        //         // IsDelete: in_data["[Header]IsDelete"],
        //         // JurisdictionID: in_data["[Header]JurisdictionID"],
        //         // KeyID: in_data["[Header]KeyID"],
        //         // LoginSchoolName: in_data["[Header]LoginSchoolName"],
        //         // LoginUserName: in_data["[Header]LoginUserName"],
        //         // Major: in_data["[Header]Major"],
        //         // Mobile: in_data["[Header]Mobile"],
        //         // PassWord: in_data["[Header]PassWord"],
        //         // School: in_data["[Header]School"],
        //         // SchoolID: in_data["[Header]SchoolID"],
        //         // UserAccount: in_data["[Header]UserAccount"],
        //         UserInfoID: in_data["[Header]UserInfoID"],
        //         // UserName: in_data["[Header]UserName"],
        //         // UserType: in_data["[Header]UserType"],
        //         // id: in_data["[Header]id"],
        //     };
        // }
        /*if (test) {
            localStorage.Header = JSON.stringify({
                BodyID: null,
                CityLevels: null,
                CreateTime: "2022-06-24 14:46:39",
                CreateUserInfoID: "4265e31a-4452-47e3-b5a6-7dc96ecd4a29",
                ExpirationTime: "2022-06-24 15:46:39",
                Guid: "7027ddf6-0455-4fa1-9692-27b4122e0ecd",
                IsDelete: 0,
                JurisdictionID: null,
                KeyID: "0200004a-9de2-402f-bc61-0500121157d9",
                OrgLevels: null,
                SchoolID: "705424ee-f2f5-4d51-922e-8912beda7ef1",
                UserInfoID: "4265e31a-4452-47e3-b5a6-7dc96ecd4a29",
                UserType: 0,
            });
        }*/
        // 解决路由参数和URL挂载问题
        // var url_obj = this._ajax.get_url_argument_obj()
        // if (url_obj.loginnum) {
        //     this.store.user.ID = url_obj.loginnum
        //     this.store.user.account = url_obj.loginnum
        //     this.store.user.name = url_obj.name
        //     this.store.user.school = url_obj.school
        //     localStorage.user = JSON.stringify(this.store.user)
        //     var date = new Date()
        //     var m = date.getMonth() + 1
        //     var d = date.getDate()
        //     localStorage.date = date.getFullYear() + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d)
        // }

        this._ajax.app = this;
        // if (location.pathname != "/" && !test) {
        //     this._ajax.post({
        //         url: "GetUserInfo",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res);
        //             this._ajax.store.Header = res.Results;
        //         },
        //     });
        // }
        // this.updateFun();
        // this.getMessageFun();
        // setInterval(() => {
        //     this.updateFun();
        // }, 1000 * 60 * 5);
        // setInterval(() => {
        //     this.getMessageFun();
        // }, 1000 * 60 * 30);
    },
    methods: {
        // updateFun() {
        //     this._ajax.post({
        //         url: "GetUpdate",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res)
        //             if (res.Results) {
        //                 this.$notify({
        //                     type: "warning",
        //                     title: "更新提示",
        //                     message: res.Results,
        //                     duration: 0,
        //                 });
        //             }
        //         },
        //     });
        // },
        // getMessageFun() {
        //     this._ajax.post({
        //         url: "GetMessage",
        //         data: {},
        //         success: (res) => {
        //             // console.log("res:", res.data)
        //             if (res.Results) {
        //             let html = res.Results
        //                 this.$notify({
        //                     type: "warning",
        //                     // grouping: true,
        //                     title: "新消息提示",
        //                     dangerouslyUseHTMLString: true,
        //                     message: html,
        //                     duration: 0,
        //                 });
        //             }
        //         }
        //     });
        // },
        sub() {
            // console.log(this.form)
            localStorage.HeaderKey = this.form
            this.$router.push({
                name: "news_list",
            });
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
};
</script>
